import Vue from "vue";
import App from "./app.vue";
import router from "./services/router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import './modules';
import './app.scss';
import Keycloak from "keycloak-js";

Vue.config.productionTip = false;

Vue.component('Button', () => import('@rr-component-library/button/src/main'));
Vue.component('Row', () => import('@rr-component-library/row/src/main'));
Vue.component('Col', () => import('@rr-component-library/col/src/main'));
Vue.component('Input', () => import('@rr-component-library/input/src/main'));
Vue.component('Alert', () => import('@rr-component-library/alert/src/main'));
Vue.component('Link', () => import('@rr-component-library/link/src/main'));
Vue.component('Checkbox', () => import('@rr-component-library/checkbox/src/main'));
Vue.component('Field', () => import('@rr-component-library/field/src/main'));
Vue.component('Icons', () => import('@rr-component-library/icons'));
Vue.component('Icon', () => import('@rr-component-library/icon'));

import vmodal from 'vue-js-modal';
Vue.use(vmodal);

let isTouch = false;
if ('ontouchstart' in document.documentElement) {
  isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

function check() {
  const w: any = window;
  if (w.MYSPORT_PHOTO_APP_SETTINGS.themeDone === true) {
    init();
    return;
  }
  setTimeout(() => { check(); }, 50);
}

function getKeycloakUrl(uri: any) {
  const devKeycloakDomain = process.env.NODE_ENV === "production" ? '' : 'https://euro.rr-dev.ru';
  const defaultUrl: string = devKeycloakDomain ? `${devKeycloakDomain}/auth` : `${window.location.origin}/auth`;
  if (uri) {
    if (uri.indexOf("/") === 0) return devKeycloakDomain ? `${devKeycloakDomain}${uri}` : `${window.location.origin}${uri}`;
    return uri;
  }
  return defaultUrl;
}

function init() {
  const w: any = window;
  const openId = w.MYSPORT_PHOTO_APP_SETTINGS["openId"];

  if (!openId) {
    w.MYSPORT_PHOTO_APP = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount("#app");
    return;
  }
  
  const initOptions = {
    url: getKeycloakUrl(openId.uri),
    realm: openId.realm,
    clientId: openId.clientId,
  }
  const keycloak = new Keycloak(initOptions);

  keycloak.init({
    redirectUri: window.location.href,
    scope: "openid profile email",
    flow: "implicit",
    onLoad: "check-sso",
  }).then(() => {  
    const w: any = window;
    w.MYSPORT_PHOTO_APP = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App, { props: { keycloak: keycloak } })
    }).$mount("#app");
  }).catch(() => { });
}

check();
